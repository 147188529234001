import { Icon, Text, Tooltip, chakra, useToken } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import clamp from 'lodash/clamp'
import React from 'react'
import { IconType } from 'react-icons'
import { BiCaretDown, BiCaretUp } from 'react-icons/bi'
import {
  Cell,
  Pie,
  PieChart,
  PieProps,
  ResponsiveContainer,
  Sector,
} from 'recharts'

import { WidgetGoal, WidgetTrendType } from '../../../../types'
import { isWithinRange } from '../../../../utils/referenceRange'
import { trendLabels } from '../SingleValueDisplay/utils'
import InsetShadowFilter from './InsetShadowFilter'
import { ValueDisplayContextType } from './ValueDisplayContext'

const SVGText = chakra('text', {
  baseStyle: {
    dominantBaseline: 'middle',
    textAnchor: 'middle',
    fontWeight: 'medium',
  },
})

const SVGHtml = chakra('foreignObject', {
  baseStyle: {
    height: '20%',
    width: '100%',
    fontWeight: 'medium',
    overflow: 'visible',
  },
})

type GaugeDisplayProps = {
  isPreview?: boolean
  value: number | undefined
  formatValue?: (val: number) => string
  goal?: WidgetGoal | null
  showPreviousPeriodTrendIndicator?: boolean | null
  benchmark?: WidgetTrendType
  referenceValue?: number
  getPercentageChange?: number
  unit?: string
  min?: number
  max?: number
  width?: number
  height?: number
}

const defaultFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
}

const defaultFormatFn = (val: number): string => i18n.number(val, defaultFormat)

type SectorType = {
  value: number
  color: string
}

type PieRadius = Pick<PieProps, 'outerRadius' | 'innerRadius'>

type UseGaugeDisplay = {
  goal: {
    data: SectorType[]
    radius: PieRadius
    minValue: number | null
    maxValue: number | null
  }
  gauge: {
    data: SectorType[]
    radius: PieRadius
    fontSize: number
  }
  pieProps: Omit<PieProps, 'dataKey' | 'ref'>
  colors: {
    arcColor: string
    goalColor: string
    successColor: string
    primaryColor: string
    bodyTextColor: string
    trendColor: string
  }
}

function useGaugeDisplay({
  goal,
  value,
  max = 100,
  width = 100,
  height = 100,
}: GaugeDisplayProps): UseGaugeDisplay {
  const goalMin = goal?.min != null ? clamp(goal.min, 0, max) : null
  const goalMax = goal?.max != null ? clamp(goal.max, 0, max) : null
  const hasGoalRange = goalMin != null || goalMax != null
  const valueIsWithinRange = isWithinRange(value, goalMin, goalMax)

  const halfHeight = height / 2
  const maxRadius = Math.min(width / 2, height)

  const valueFontSize = maxRadius * 0.36

  const goalArcThickness = maxRadius * 0.04

  const goalRadius = {
    innerRadius: maxRadius - goalArcThickness,
    outerRadius: maxRadius,
  }

  const gaugeRadius = {
    innerRadius: (hasGoalRange ? goalRadius.innerRadius : maxRadius) * 0.7,
    outerRadius: (hasGoalRange ? goalRadius.innerRadius : maxRadius) * 0.97,
  }

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: halfHeight + goalRadius.outerRadius / 2,
    paddingAngle: 0,
  }

  const [
    arcColor,
    goalColor,
    successColor,
    primaryColor,
    bodyTextColor,
    trendColor,
  ] = useToken('colors', [
    'gray.200',
    'blackAlpha.500',
    'segmentQuaternary',
    'segmentPrimary',
    'text',
    'gray.600',
  ])

  const gaugeData = React.useMemo(() => {
    return [
      {
        value: value ?? 0,
        color: valueIsWithinRange ? successColor : primaryColor,
      },
      {
        value: max - (value ?? 0),
        color: arcColor,
      },
    ]
  }, [value, max, valueIsWithinRange, successColor, primaryColor, arcColor])

  const goalData = React.useMemo(() => {
    const goalSector = {
      from: goalMin ?? 0,
      to: goalMax ? goalMax : goalMin ? max : 0,
    }
    return [
      {
        value: goalSector.from,
        color: 'transparent',
      },
      {
        value: goalSector.to - goalSector.from,
        color: valueIsWithinRange ? successColor : goalColor,
      },
      {
        value: max - goalSector.to,
        color: 'transparent',
      },
    ]
  }, [goalMin, goalMax, valueIsWithinRange, successColor, goalColor, max])

  return {
    goal: {
      radius: goalRadius,
      data: goalData,
      minValue: goalMin,
      maxValue: goalMax,
    },
    gauge: {
      radius: gaugeRadius,
      data: gaugeData,
      fontSize: valueFontSize,
    },
    pieProps,
    colors: {
      arcColor,
      goalColor,
      successColor,
      primaryColor,
      bodyTextColor,
      trendColor,
    },
  }
}

const createTooltipText = (
  value: number | undefined,
  referenceValue: number,
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  unit = '',
  percentageChange: number | undefined,
  formatValue: ValueDisplayContextType['formatValue'],
  trendLabel: string | undefined,
): string => {
  if (trendLabel) return t`Shows hit rate from ${trendLabel}`

  if (referenceValue === 0 && value !== 0) {
    return t`The percentage change is not defined since the value of the previous period is ${formatValue(
      referenceValue,
    )}${unit}.`
  }
  if (value === undefined || percentageChange === undefined) {
    return t`The percentage change is not defined.`
  }
  const sign = percentageChange > 0 ? '+' : ''
  const formattedPercentageChange = `${sign}${defaultFormatFn(
    percentageChange,
  )}%`
  return t`The percentage change between the value of the previous period (${formatValue(
    referenceValue,
  )}${unit}) and the value of the current period (${formatValue(
    value,
  )}${unit}) is ${formattedPercentageChange}`
}

function getPercentageChange(
  value: number | undefined,
  referenceValue: number | undefined,
): number | undefined {
  if (referenceValue === 0 && value === 0) {
    return 0
  }
  if (
    referenceValue === undefined ||
    value === undefined ||
    referenceValue === 0
  ) {
    return undefined
  }
  return ((value - referenceValue) / referenceValue) * 100
}

const getTrendIcon = (
  percentageChange: number | undefined,
): IconType | null => {
  if (percentageChange === undefined) return null
  if (percentageChange < 0) return BiCaretDown
  return BiCaretUp
}

const getPreviewGaugeData = (
  value?: number,
): { value: number; color: string }[] => [
  {
    value: value ?? 0,
    color: '#E0E0E0',
  },
  {
    value: 100 - (value ?? 0),
    color: '#eeeeee',
  },
]

const GaugeDisplay: React.FC<GaugeDisplayProps> = (props) => {
  const {
    isPreview,
    value,
    unit,
    showPreviousPeriodTrendIndicator,
    referenceValue,
    benchmark,
    formatValue = defaultFormatFn,
  } = props
  const { colors, gauge, goal, pieProps } = useGaugeDisplay(props)

  const hasGoalRange = goal.minValue != null || goal.maxValue != null
  const valueIsWithinRange = isWithinRange(value, goal.minValue, goal.maxValue)

  const percentageChange = getPercentageChange(value, referenceValue)
  const TrendIcon = getTrendIcon(percentageChange)

  const RenderGoalSector = (props: {
    cx?: number
    cy?: number
    midAngle?: number
    innerRadius?: number
    outerRadius?: number
    startAngle?: number
    endAngle?: number
  }): React.ReactElement => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props

    return (
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={valueIsWithinRange ? colors.successColor : colors.goalColor}
      />
    )
  }

  const gaugeData = isPreview ? getPreviewGaugeData(value) : gauge.data
  const trendLabel = trendLabels.get(benchmark)
  const isBenchmarkTrend = benchmark === 'Trend'

  return (
    <PieChart
      width={props.width}
      height={props.height}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <defs>
        <InsetShadowFilter id="inset-shadow-filter" />
      </defs>
      <Pie
        data={gauge.data}
        dataKey="value"
        fill="orangered"
        {...pieProps}
        {...gauge.radius}
      >
        {gaugeData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color}
            filter="url(#inset-shadow-filter)"
            stroke="rgba(255,255,255,0.7)"
          />
        ))}
      </Pie>
      {hasGoalRange && (
        <Pie
          activeIndex={1}
          activeShape={RenderGoalSector}
          data={goal.data}
          dataKey="value"
          fill="none"
          stroke="none"
          {...pieProps}
          {...goal.radius}
        />
      )}

      <g transform={`translate(${pieProps.cx}, ${pieProps.cy})`}>
        <SVGText
          style={{
            fontSize: `${gauge.fontSize}px`,
          }}
          fill={
            isPreview
              ? 'gray.800'
              : valueIsWithinRange
                ? colors.successColor
                : colors.primaryColor
          }
          // Pull the text a little up so that a comma (hangs below the text baseline) is fully visible.
          dy={gauge.fontSize * -0.3}
        >
          {value != null && (
            <>
              <tspan>{formatValue(value)}</tspan>
              {unit != null && (
                <tspan
                  dx={gauge.fontSize * 0.1}
                  fontSize="0.6em"
                  dominantBaseline="mathematical"
                >
                  {unit}
                </tspan>
              )}
            </>
          )}
        </SVGText>

        {showPreviousPeriodTrendIndicator &&
          percentageChange != null &&
          referenceValue != null && (
            <Tooltip
              isDisabled={isPreview}
              label={createTooltipText(
                value,
                referenceValue,
                unit,
                percentageChange,
                formatValue,
                trendLabel ? i18n._(trendLabel.title) : undefined,
              )}
              hasArrow
            >
              <SVGHtml
                transform={`translate(-${pieProps.cx}px, ${
                  gauge.fontSize * -1.6
                }px)`}
                style={{
                  fontSize: `${gauge.fontSize * 0.5}px`,
                }}
                fill={colors.trendColor}
                color={colors.trendColor}
                css={css`
                  tspan {
                    display: flex;
                    justify-content: center;
                    align-items: end;
                  }
                  text {
                    font-size: ${gauge.fontSize * 0.4}px;
                  }
                  .value {
                    align-items: end;
                    line-height: 1em;
                    color: ${colors.bodyTextColor};
                    gap: ${gauge.fontSize * 0.1}px;
                  }
                `}
              >
                {value != null && (
                  <>
                    <tspan dy={gauge.fontSize * -1.2}>
                      {trendLabel && (
                        <Text fontWeight="normal" fontSize="0.5em">
                          {i18n._(trendLabel.short)}
                        </Text>
                      )}
                      {percentageChange !== 0 &&
                        isBenchmarkTrend &&
                        TrendIcon != null && <Icon as={TrendIcon} />}
                      {isPreview ? (
                        <>
                          <Icon
                            viewBox="0 0 200 100"
                            color="red.500"
                            mx="1"
                            w="8"
                          >
                            <rect
                              width="200"
                              height="120"
                              rx="70"
                              ry="70"
                              style={{
                                fill: 'gray.500',
                              }}
                            />
                          </Icon>
                          <tspan className="value">
                            <text>{unit}</text>
                          </tspan>
                        </>
                      ) : (
                        <tspan className="value">
                          {isBenchmarkTrend
                            ? formatValue(Math.abs(percentageChange))
                            : formatValue(Math.abs(referenceValue))}
                          <text>%</text>
                        </tspan>
                      )}
                    </tspan>
                  </>
                )}
              </SVGHtml>
            </Tooltip>
          )}
      </g>
    </PieChart>
  )
}

export default function GaugeDisplayResponsiveWrapper(
  props: GaugeDisplayProps,
): React.ReactElement {
  return (
    <ResponsiveContainer>
      <GaugeDisplay {...props} />
    </ResponsiveContainer>
  )
}
